import { NgModule } from "@angular/core";
import { addNavMenuSection, SharedModule } from "@vendure/admin-ui/core";

@NgModule({
  imports: [SharedModule],
  providers: [
    addNavMenuSection(
      {
        id: "blogs-nav",
        label: "Blogs",
        items: [
          {
            id: "blogs-nav",
            label: "Blogs",
            routerLink: ["/extensions/blogs"],
            // Icon can be any of https://core.clarity.design/foundation/icons/shapes/
            icon: "info-standard",
          },
        ],
      },
      "customers"
    ),
  ],
})
export class BlogNavModule {}
