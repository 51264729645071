import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactExtensionModule } from './extensions/c203ecf55e9b231fbbaf2d10ca61de5fb2583f6816658711c7e867b8d87906f7/contact-ui-extension.module';
import { BlogNavModule } from './extensions/03c09ce034f5df718345291da8b44b0165dc1a48691cd14693458e35ccbef25d/blog-nav.module';



@NgModule({
    imports: [CommonModule, ContactExtensionModule, BlogNavModule],
    providers: [],
})
export class SharedExtensionsModule {}
